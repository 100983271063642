<template>
    <div class="d-flex flex-wrap w-100 align-items-start">
        <div
            :class="[
                'card drop-down mt-3 mb-1 h-auto min-width-50',
                { 'card-hover drop-down pointer': expandable || !!link },
            ]"
        >
            <div class="card-body p-0">
                <div class="px-3 py-4 d-flex align-items-center" @click.stop="expandToggle">
                    <div class="flex-1 flex-wrap d-flex justify-content-between align-items-center">
                        <div class="mr-3">
                            <h4 class="p-0 my-1">
                                <AlertIcon v-if="alertIconStatus" decorative :status="alertIconStatus" />

                                {{ title }}
                                <small>
                                    <VIcon
                                        v-if="hasSettings"
                                        class="override-modal-icon pointable pb-1"
                                        name="settings-filled"
                                        @click.stop.prevent="$emit('showSettings')"
                                    />
                                </small>
                            </h4>
                            <small v-if="additional">{{ additional }}</small>
                        </div>
                        <div class="mr-2 p-0 my-1 check-description">
                            <slot name="description" />
                            <VIcon
                                v-if="helpText"
                                class="override-modal-icon mr-2"
                                data-toggle="tooltip"
                                decorative
                                name="help-question-filled"
                                :title="helpText"
                            />
                        </div>
                    </div>
                    <VIcon
                        v-if="expandable"
                        decorative
                        :name="expanded ? 'chevron-up-thick' : 'chevron-down-thick'"
                        size="2rem"
                    />
                </div>

                <PoseTransition>
                    <CardContent v-if="expanded" @click.stop>
                        <div class="d-pt-2 border-top border-1">
                            <slot name="checkDetail" />
                            <slot />
                        </div>
                    </CardContent>
                </PoseTransition>
            </div>
        </div>

        <VCheckScript v-if="script" :script="script" />
    </div>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose';

import AlertIcon from '@/components/AlertIcon.vue';
import { isUrlExternal } from '@/utils/browser';

export default {
    components: {
        AlertIcon,
        PoseTransition,

        CardContent: posed.div({
            enter: { height: 'auto', transition: { duration: 200 } },
            exit: { height: 0, transition: { duration: 200 } },
        }),
    },
    inject: ['analyticsProvider'],
    name: 'Check',
    props: {
        additional: { default: '', type: String },
        alertIcon: { default: 'none', type: String },
        expandable: { default: true, type: Boolean },
        hasSettings: { default: false, type: Boolean },
        helpText: { default: null, type: String },
        link: { default: '', type: String },
        name: { default: '', type: String },
        script: { default: '', type: String },
        title: { required: true, type: String },
    },

    data() {
        return {
            expanded: false,
        };
    },
    computed: {
        alertIconStatus() {
            switch (this.alertIcon) {
                case 'exclamation-circle':
                    return 'error';
                case 'check-circle':
                    return 'success';
                case 'exclamation-triangle':
                    return 'warning';
                case 'none':
                    return 'none';
                case 'error':
                case 'warning':
                case 'success':
                    return this.alertIcon;
                default:
                    return null;
            }
        },
        analyticsName() {
            if (this.expandable) {
                const cardStatus = this.expanded ? 'Open' : 'Close';

                return `${cardStatus} ${this.name}`;
            }

            return this.name;
        },
    },
    methods: {
        expandToggle() {
            if (this.expandable) {
                this.expanded = !this.expanded;
            } else if (this.link) {
                if (isUrlExternal(this.link)) {
                    window.open(this.link, '_blank');
                } else {
                    this.$router.push(this.link);
                }
            }

            Boolean(this.name) && this.analyticsProvider.trackElementClick(this.analyticsName, 'Card');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.check-description {
    font-size: 18px;
}

.min-width-50 {
    flex: 1 50%;
}

.override-modal-icon {
    color: #51495a;
    opacity: 0.5;

    &:hover {
        color: get-color(blue);
        opacity: 1;
    }
}

@media (width <= 1100px) {
    .min-width-50 {
        min-width: 100%;
    }
}
</style>
