<template>
    <div>
        <input v-model="filter" class="form-control mb-2" :placeholder="String(translator.t(t.search))" />

        <ul
            :class="[
                'list-unstyled bg-white p-0 mb-0 simple-item-list',
                flowCreator ? 'list-view' : longList ? 'long-list' : 'short-list',
            ]"
        >
            <li
                v-for="(item, index) in filteredItems"
                :key="index"
                class="px-3 py-3 reveal d-flex align-items-center"
                @click.prevent="$emit('clicked', item)"
            >
                <span v-if="useItemComponent" class="flex-grow-1">
                    <slot :item="item" name="itemComponent" />
                </span>
                <span v-else-if="tags" class="flex-grow-1">{{ item.name }} ({{ item.value }})</span>
                <span v-else class="flex-grow-1" v-html="titleText(item)" />
                <span
                    v-if="actionText"
                    :class="[
                        alwaysRevealTarget ? '' : 'reveal-target',
                        'float-right pl-2',
                        actionTextColour ? 'text-' + actionTextColour : '',
                    ]"
                >
                    <span v-if="actionIconName">
                        <VIcon :name="actionIconName" />
                    </span>
                    {{ actionText }}
                </span>
            </li>
            <!-- add no further options available -->
            <li v-if="!filteredItems.length" class="px-3 py-1 nohover">{{ translator.t(t.noResults) }}</li>
        </ul>
    </div>
</template>

<script>
import { encode } from 'html-entities';

import { useVueI18n } from '@/composables/useVueI18n';

import { translations as t } from './SimpleList.translations';

export default {
    computed: {
        filteredItems() {
            const filtered = this.filter ? this.items.filter(this.searchFilter(this.filter)) : this.items;

            return filtered.sort(this.sortItems);
        },
    },
    data() {
        return {
            filter: '',
        };
    },
    name: 'SimpleList',
    props: {
        actionIconName: { default: null, type: String },
        actionText: { default: null, type: String },
        actionTextColour: { default: null, type: String },
        alwaysRevealTarget: { default: false, type: Boolean },
        flowCreator: { default: false, type: Boolean },
        items: { required: true, type: Array },
        longList: { default: false, type: Boolean },
        searchFilter: {
            default: (searchText) => {
                return (item) => {
                    return item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                };
            },
            type: Function,
        },
        sortItems: { default: (a, b) => a.name.localeCompare(b.name), type: Function },
        tags: { default: false, type: Boolean },
        titleText: {
            default: (item) => {
                return encode(item.name);
            },
            type: Function,
        },
        useItemComponent: { default: false, type: Boolean },
    },
    setup() {
        const translator = useVueI18n();

        return { t, translator };
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.simple-item-list {
    border: 1px solid #b7b0bf70;
    box-shadow: inset 0 0 10px -8px rgba(0, 0, 0, 44%);
    overflow-y: scroll;

    & > li {
        color: get-color(charcoal, lite) !important;
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out;
    }

    & > li:nth-child(even) {
        background-color: get-color(silver, lite);
    }

    & > li:hover:not(.nohover) {
        background-color: get-color(silver, medium) !important;
        color: get-color(blue) !important;
        cursor: pointer;
    }
}

.list-view {
    max-height: calc(75vh - 150px);
}

.long-list {
    height: 300px;
}

.short-list {
    max-height: 160px;
    min-height: 50px;
}

.text-danger {
    color: #dd4822 !important;
}
</style>
