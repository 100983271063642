import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import { routes } from './routes';

Vue.use(VueRouter);

export function createRouter() {
    const router = new VueRouter({
        mode: 'history',
        routes: routes,
        scrollBehavior(_to, _from, savedPosition) {
            return savedPosition ?? { x: 0, y: 0 };
        },
    });

    router.beforeEach((to, _from, next) => {
        const disableLegacyStyles = Boolean(to.meta?.disableLegacyStyles);
        const htmlElement = document.querySelector('html');

        htmlElement?.classList.toggle('precision-classic', !disableLegacyStyles);
        htmlElement?.classList.toggle('dext-theme-light', disableLegacyStyles);
        store.getters['teams/wrongTeam'] ? (window.location.pathname = '/refresh-team') : next();
    });

    router.afterEach((to) => {
        if (window.gaid) {
            window.gtag('config', window.gaid, {
                page_path: to.path,
                page_title: to.name || document.title,
            });
        }
    });

    return router;
}
